<template>
  <div class="container">
    <el-card class="box-card">
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id" width="50"></el-table-column>
        <el-table-column align="center" label="提现订单号" width="250" prop="txOrderNo"></el-table-column>
        <el-table-column align="center" label="银行开户真实姓名" prop="bankRealName"></el-table-column>
        <el-table-column align="center" label="银行预留手机号码" prop="bankReservePhone"></el-table-column>
        <el-table-column align="center" label="银行名称" prop="bankName"></el-table-column>
        <el-table-column align="center" label="银行卡号" width="250" prop="bankCard"></el-table-column>
        <el-table-column align="center" label="提现金额" prop="amount"></el-table-column>
        <el-table-column align="center" label="描述" prop="txDesc"></el-table-column>
        <el-table-column align="center" width="150" label="状态">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.returnDesc" placement="top"
                        v-if="scope.row.status != 0">
              <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'" disable-transitions>
                {{ scope.row.status == 1 ? '成功' : '失败' }}
                <i class="el-icon-warning-outline"></i>
              </el-tag>
            </el-tooltip>
            <el-tag type="" disable-transitions v-else>提现中</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" label="操作">
          <template slot-scope="scope" v-if="scope.row.status == 0">
            <el-button type="success" @click="handler(scope.row)">处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="处理" :visible.sync="widthraShow" :close-on-click-modal="false" width="36%">
      <div class="layout_sa">
        <div class="list">提现订单号:{{ withdraInfo.txOrderNo }}</div>
        <div class="list">银行开户真实姓名:{{ withdraInfo.bankRealName }}</div>
      </div>
      <div class="layout_sa">
        <div class="list">银行预留手机号码:{{ withdraInfo.bankReservePhone }}</div>
        <div class="list">银行名称:{{ withdraInfo.bankName }}</div>
      </div>
      <div class="layout_sa">
        <div class="list">银行卡号:{{ withdraInfo.bankCard }}</div>
        <div class="list">提现金额:{{ withdraInfo.amount }}</div>
      </div>
      <div class="layout_sa">
        <div class="list">用户描述:{{ withdraInfo.txDesc }}</div>
        <div class="list">申请时间：{{ withdraInfo.createTime }}</div>
      </div>
      <el-input class="textarea" type="textarea" :rows="3" placeholder="请输入审核描述"
                v-model="withdraInfo.returnDesc"></el-input>
      <div style="text-align: right;margin-top:20px;">
        <el-button @click="widthraShow = false">取消</el-button>
        <el-button type="danger" @click="changeCheck('deny')">拒绝</el-button>
        <el-button type="primary" @click="changeCheck('agree')">同意</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {withdrawalList, withdrawalExamine} from "../../apis/withdraw";

export default {
  data() {
    return {
      tableData: [],
      query: {
        current: 1,
        size: 10,
      },
      total: null,
      loading: false,
      teacherShow: false, // 添加对话框
      withdraInfo: {},
      widthraShow: false
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * @param {string} status deny 拒绝 agree 同意
     */
    async changeCheck(status) {
      this.withdraInfo.status = status == 'deny' ? 2 : 1
      const res = await withdrawalExamine(this.withdraInfo)
      this.widthraShow = false
      this.getData()
      this.$message.success(res.msg || '操作成功')
    },
    handler(item) {
      this.withdraInfo = item
      this.widthraShow = true
    },

    handleSizeChange(size) {
      this.query.size = size;
      this.getData();
    },
    handleCurrentChange(page) {
      this.query.current = page;
      this.getData();
    },

    async getData() {
      this.loading = true;
      const res = await withdrawalList(this.query);
      const {records, total, current, size} = res.data;
      this.tableData = records;
      this.total = total;
      this.query.current = current;
      this.query.size = size;
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  flex: 1;
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px #eee dashed;
}
</style>