// 提现管理
import axios from 'axios'
import QS from 'qs'

// 提现列表
export const withdrawalList = params => {
    return axios.post('/api/withdrawal/page', QS.stringify(params)).then(res => res.data)
}
// 提现审核
export const withdrawalExamine = params => {
    return axios.post('/api/withdrawal/examine', QS.stringify(params)).then(res => res.data)
}